<template>
  <!-- 横屏 -->
  <horizontalScreen class="v-read-course">
    <!-- <scaleWrap> -->
    <div v-if="list.length" class="home" ref="home" :style="{ backgroundImage: `url(${currentBg})` }">
      <!-- <img class="logo" src="@/assets/index/img-logo.png" /> -->
      <!-- <img class="game-logo" src="@/assets/index/img-ztrz-new.png"/> -->
      <click-audio ref="clickAudio"></click-audio>

      <audio ref="myAudio" id="myAudio" preload></audio>
      <div class="btn-box" ref="btnBox">
        <img class="btn-back" @click="backHandler" src="@/assets/read/1.png" />
        <!-- 慢 -->
        <img @click="playTypeHandler(1, $event)" class="btn-back" src="@/assets/read/2.png" />
        <!-- 中 -->
        <img @click="playTypeHandler(2, $event)" class="btn-back" src="@/assets/read/3.png" />
        <!-- 快 -->
        <img @click="playTypeHandler(3, $event)" class="btn-back" src="@/assets/read/4.png" />
        <!-- 是否显示横版竖版切换按钮 -->
        <!-- <template v-if="isShowHorizontal"> -->
        <!-- 横版 -->
        <!-- <img class="btn-back" @click="horizontalHandler" v-if="isHorizontal" src="@/assets/read/5.png" /> -->
        <!-- 竖版 -->
        <!-- <img class="btn-back" @click="horizontalHandler" v-if="!isHorizontal" src="@/assets/read/9.png" /> -->
        <!-- </template> -->
        <!-- 播放 -->
        <img @click="playHandler" class="btn-back" v-if="!playing" src="@/assets/read/6.png" />
        <!-- 暂停 -->
        <img @click="pauseHandler" class="btn-back" v-if="playing" src="@/assets/read/10.png" />
        <div class="btn-prev">
          <img class="btn-prev" v-if="index > 0" @click="prevClick" src="@/assets/read/7.png" />
        </div>
        <div class="btn-next">
          <img class="btn-next" v-if="index < list.length - 1" @click="nextClick" src="@/assets/read/8.png" />
        </div>
      </div>
    </div>
    <!-- </scaleWrap> -->
  </horizontalScreen>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
import horizontalScreen from '@/components/horizontal-screen'
import { isEmpty } from 'lodash'
import ClickAudio from '@/components/clickAudio.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReadCourse',
  components: {
    horizontalScreen, ClickAudio
  },
  beforeDestroy () {
    this.audioList = []
    this.$refs.myAudio.pause()
  },
  computed: {
    ...mapGetters(['albumId']),
    isShowHorizontal () {
      return this.albumId != 1
    },
    current () {
      return this.list[this.index]
    },
    currentBg () {
      if (this.isHorizontal) {
        return this.current.bg
      }
      return this.current.bg2
    }
  },
  data () {
    return {
      readingAudio: {},
      course: Number(this.$route.query.course),
      playing: false, // 播放，暂停
      isHorizontal: true, // 横版
      index: 0,
      audioList: [],
      // audio: {
      //   3: require(`@/assets/read/1-1-kuai.mp3`),
      //   2: require(`@/assets/read/1-1-zhong.mp3`),
      //   1: require(`@/assets/read/1-1-man.mp3`)
      // },
      // list: [
      //   {
      //     bg: require(`@/assets/read/1-1-shu.jpg`),
      //     bg2: require(`@/assets/read/1-1-heng.jpg`)
      //   },
      //   {
      //     bg: require(`@/assets/read/1-2-shu.jpg`),
      //     bg2: require(`@/assets/read/1-2-heng.jpg`)
      //   }
      // ],
      list: [],
      clickAudio: require(`@/assets/sounds/click.mp3`)
    }
  },
  created () {
    this.fetchList()
  },
  methods: {
    isEmpty,
    ...mapActions(['getReading']),
    async fetchList () {
      this.$loading.show('数据加载中。。。')
      try {
        const res = await this.getReading({
          gradeId: this.course
        })
        console.log('getReading', res)
        this.readingAudio = {
          3: res.result.quickUrl,
          2: res.result.middleUrl,
          1: res.result.slowUrl
        }
        const horizontal = res.horizontal.filter(item => item.url)
        const vertical = res.vertical.filter(item => item.url)
        console.log('res', res)
        for (let i = 0; i < res.total; i++) {
          // this.list[i] = ({
          //   bg: horizontal[i],
          //   bg2: vertical[i]
          // })
          this.$set(this.list, i, {
            bg: horizontal[i].url,
            bg2: vertical[i].url
          })
        }
        console.log('list', this.list)
      } finally {
        this.$loading.hide()
      }
    },
    animeHandler (event) {
      const targetNode = event.target
      anime({
        targets: targetNode,
        scale: [
          { value: 1, duration: 100 },
          { value: 1.1, duration: 100 },
          { value: 1, duration: 100 }
        ],
        complete: () => {
          targetNode.style.transform = 'none'
        }
      });
    },
    async backHandler (event) {
      this.animeHandler(event)
      await this.$refs.clickAudio.playAudio()
      this.$router.back()
      window.wx.miniProgram.navigateBack()
    },
    playTypeHandler (type, event) {
      this.animeHandler(event)
      // 1man 2zhong 3kuai
      this.$refs.clickAudio.playAudio()
      this.audioList = []
      this.audioList = [this.readingAudio[type]]
      this.playAudio()
    },
    // 暂停
    pauseHandler (event) {
      this.animeHandler(event)
      this.$refs.clickAudio.playAudio()
      this.$refs.myAudio.pause()
      this.playing = false
    },
    // 播放
    playHandler (event) {
      this.animeHandler(event)
      this.$refs.clickAudio.playAudio()
      if (this.playing === false) {
        this.playing = true
      }
      if (this.$refs.myAudio.src) {
        this.$refs.myAudio.play()
      } else {
        // 默认慢速
        this.audioList = [this.readingAudio[1]]
        this.playAudio()
      }
    },
    // 上一页
    prevClick (event) {
      this.animeHandler(event)
      this.$refs.clickAudio.playAudio()
      if (this.index > 0) {
        this.index--
      }
    },
    // 下一页
    nextClick (event) {
      this.animeHandler(event)
      this.$refs.clickAudio.playAudio()
      console.log(this.index, this.list.length)
      if (this.index < this.list.length - 1) {
        this.index++
      }
    },
    horizontalHandler (event) {
      this.animeHandler(event)
      this.$refs.clickAudio.playAudio()
      console.log('isHorizontal', this.isHorizontal)
      // 横竖版切换
      this.isHorizontal = !this.isHorizontal
      console.log('isHorizontal', this.isHorizontal)
    },
    playAudio () {
      const myAudio = document.getElementById('myAudio')
      myAudio.src = this.audioList[0]
      myAudio.play()
      this.playing = true
      let index = 0
      myAudio.onended = () => {
        console.log(index, this.audioList)
        if (index < this.audioList.length - 1) {
          myAudio.src = this.audioList[index + 1];
          myAudio.play()
          index++;
        } else {
          this.playing = false
          this.audioList = []
        }
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.v-read-course {

  // font-family: 'Alibaba-PuHuiTi-Bold';
  // font-family: 'FZHuPo-M04S';
  audio {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: -1;
  }

  .home {
    height: 100%;
    width: 100%;
    background: url(~@/assets/word/bg-word.png) center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  // .logo {
  //   width: 28px;
  //   left: 5px;
  //   top: 10PX;
  //   position: fixed;
  // }
  .btn-box {
    height: 18%;
    position: fixed;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    bottom: 5px;

    .btn-back {
      width: 56px;
      height: 56px;
    }

    .btn-prev,
    .btn-next {
      width: 98.57px;
      height: 56px;
    }

  }

}
</style>
